import React from "react"
import { Link, graphql } from "gatsby"
import { animated } from "react-spring"

import Image from "../components/image"
import Layout from "../components/layout"
import { useAutoCarousel } from "../hooks"
import useItemsLayout, { sizes } from "../hooks/useCarouselItemLayout"
import styles from "./person.module.scss"

export default ({ data }) => {
  const person = data.craft.person
  const seo = person.seomatic
  const articles = data.craft.articles
  const portrait = person.portrait[0]

  let [hack, doHack] = React.useState(false)
  // For the "More Joneses" Carousel
  let people = data.craft.people.filter(p => p.id !== person.id)
  people.sort(() => Math.random() - 0.5)
  people = people.slice(0, 10)

  React.useLayoutEffect(() => {
    doHack(true)
  }, [])

  return (
    <Layout seo={seo} mode="zeus">
      <div className="page-content-module">
        <div className="wrapper">
          <div className={styles.person}>
            <div className="columns is-desktop is-multiline">
              <div className="column is-5-desktop">
                <div className={styles.namePhotoParentTablet}>
                  <div className={styles.nameMobile}>
                    <h1 className="h-1">
                      <span className="serif-italic">
                        {person.nameFirstLine}
                      </span>{" "}
                      {/* Only first line is required. */}
                      {person.nameSecondLine && (
                        <>
                          <br />
                          {person.nameSecondLine}
                        </>
                      )}
                    </h1>
                  </div>
                  <div className={styles.portrait}>
                    <Image
                      height={portrait.height}
                      width={portrait.width}
                      crop={true}
                      url={portrait.url}
                      sizes="(min-width: 1216px) 537px, (min-width: 1024px) 450px, (min-width: 768px) 370px, calc(100vw - 2.5rem)"
                      aspectRatio="2:3"
                      focalPoint={portrait.focalPoint}
                      altText={portrait.altText}
                    />
                  </div>
                </div>
              </div>
              <div className="column is-6-desktop is-offset-1-widescreen">
                <div className={styles.nameDesktop}>
                  {/*
                    Ignore this name since the first one is shown on mobile and is
                    still accessible to screen readers
                  */}
                  <p className="h-1" aria-hidden="true">
                    {/* Only first line is required. */}
                    <span className="serif-italic">{person.nameFirstLine}</span>
                    {person.nameSecondLine && (
                      <>
                        <br />
                        {person.nameSecondLine}
                      </>
                    )}
                  </p>
                </div>
                <div className={styles.tagsWrapper}>
                  {person.specialty.length > 0 && (
                    <div className={styles.tagsCol}>
                      <h3 className="body-large mb-3">Specialties</h3>
                      <ul className={styles.tags}>
                        {person.specialty.map(item => (
                          <li key={`specialty-${item.id}`}>{item.title}</li>
                        ))}
                      </ul>
                    </div>
                  )}

                  {person.exploring.length > 0 && (
                    <div className={styles.tagsCol}>
                      <h3 className="body-large mb-3">Exploring</h3>
                      <ul className={styles.tags}>
                        {person.exploring.map(item => (
                          <li key={`exploring-${item.id}`}>{item.title}</li>
                        ))}
                      </ul>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          {/* .person */}

          {articles.length > 0 && (
            <div className={styles.contributions}>
              <div className="mb-6">
                <h3 className="body-large">Contributions</h3>
              </div>
              <div className="columns is-multiline">
                {articles.map((article, i) => {
                  return (
                    <div
                      key={`article-${i}`}
                      className="column is-4-tablet is-3-desktop"
                    >
                      <Link className="img-tile" to={`/${article.uri}`}>
                        <div className="mb-4">
                          <div className="img-tile-image">
                            <Image
                              height={
                                article.coverImages[0].ratio_1x1[0].height
                              }
                              width={article.coverImages[0].ratio_1x1[0].width}
                              sizes="(min-width: 1216px) 536px, (min-width: 1024px) calc(42vw - 2.5rem), (min-width: 768px) calc(50vw - 2.5rem), calc(100vw - 2.5rem)"
                              url={article.coverImages[0].ratio_1x1[0].url}
                              altText={
                                article.coverImages[0].ratio_1x1[0].altText
                              }
                            />
                          </div>
                        </div>
                        <p className="text-white50 mb-1">
                          {article.articleSubTitle}
                        </p>
                        <div className={styles.articleText}>
                          <h3 className="body-large mb-2">
                            <span className="img-tile-title-multiline-gradient">
                              <span>{article.title}</span>
                            </span>
                          </h3>
                        </div>
                      </Link>
                    </div>
                  )
                })}
              </div>
              {/* .columns */}
            </div>
          )}
        </div>
        {/* .wrapper */}

        <div className="mb-10">
          <MoreJoneses people={people} key={`${hack ? "hacked" : ""}`} />
        </div>
      </div>
      {/* .page-content-module */}
    </Layout>
  )
}

function MoreJoneses({ people }) {
  let containerRef = React.useRef(null)
  let { columnPx, gutterPx, offsets, mode } = useItemsLayout(
    containerRef,
    people.map(p => "ratio_2x3")
  )
  let carouselRef = React.useRef()
  let offset = useAutoCarousel(carouselRef)

  return (
    <div
      className="mb-8"
      ref={carouselRef}
      style={{ position: "relative", overflow: "hidden" }}
    >
      <div className="wrapper mb-6">
        <h3 className="h-2">
          <span className="serif-italic">More</span> Joneses
        </h3>
      </div>
      <ul
        className="wrapper"
        ref={containerRef}
        style={{
          display: "flex",
          flexWrap: "no-wrap",
          flexShrink: 0,
          minHeight: 300,
          position: "relative",
        }}
      >
        {people.map((person, i) => {
          const portrait = person.portrait[0]
          let size = sizes[mode]["ratio_2x3"]
          let width = columnPx * size.columns + (size.columns - 1) * gutterPx
          let height = width * (size.aspect[1] / size.aspect[0])
          let allPeopleWidth = offsets[offsets.length - 1]
          let content = (
            <>
              <Link className="img-tile" to={`/${person.uri}`}>
                <div
                  className="mb-2"
                  style={{
                    overflow: "hidden",
                    width,
                    height,
                    backgroundColor: "black",
                  }}
                >
                  <div className="img-tile-image">
                    <Image
                      height={portrait.height}
                      width={portrait.width}
                      sizes="(min-width: 1216px) 306px, (min-width: 1024px) 352px, (min-width: 768px) 450px, calc(100vw - 2.5rem)"
                      crop={true}
                      url={portrait.url}
                      aspectRatio="2:3"
                      focalPoint={portrait.focalPoint}
                      altText={portrait.altText}
                    />
                  </div>
                </div>
                <div
                  className="body-large-after-desktop"
                  style={{ paddingBottom: "4px" }}
                >
                  <span className="img-tile-title-gradient">
                    {person.nameFirstLine} {/* Only first line is required. */}
                    {person.nameSecondLine && person.nameSecondLine}
                  </span>
                </div>
              </Link>
            </>
          )
          return (
            <React.Fragment key={person.id}>
              <animated.li
                className={styles.slide}
                style={{
                  width,
                  transform: offset.interpolate(v => {
                    let left = offsets[i]
                    let offs = v % allPeopleWidth
                    let x = offs + left - allPeopleWidth
                    return `translate3d(${x}px, 0px, 0px)`
                  }),
                }}
              >
                {content}
              </animated.li>
              <animated.li
                className={styles.slide}
                style={{
                  width,
                  transform: offset.interpolate(v => {
                    let left = offsets[i]
                    let offs = v % allPeopleWidth
                    let x = offs + left
                    return `translate3d(${x}px, 0px, 0px)`
                  }),
                }}
              >
                {content}
              </animated.li>
              <animated.li
                className={styles.slide}
                style={{
                  width,
                  transform: offset.interpolate(v => {
                    let left = offsets[i]
                    let offs = v % allPeopleWidth
                    let x = offs + left + allPeopleWidth
                    return `translate3d(${x}px, 0px, 0px)`
                  }),
                }}
              >
                {content}
              </animated.li>
            </React.Fragment>
          )
        })}
      </ul>
    </div>
  )
}

export const query = graphql`
  query($slug: String!, $id: [Craft_QueryArgument]) {
    craft {
      person: entry(slug: [$slug], section: "people") {
        title
        id
        seomatic {
          metaTagContainer
          metaLinkContainer
          metaTitleContainer
          metaJsonLdContainer
        }
        ... on Craft_people_person_Entry {
          nameFirstLine
          nameSecondLine
          specialty {
            id
            title
          }
          exploring {
            id
            title
          }
          portrait {
            id
            url
            width
            height
            focalPoint
            ... on Craft_people_Asset {
              altText
            }
          }
        }
      }
      articles: entries(section: "athenaArticles", relatedTo: $id, limit: 8) {
        id
        title
        uri
        ... on Craft_athenaArticles_athenaArticleTemplated_Entry {
          coverImages {
            ... on Craft_coverImages_BlockType {
              ratio_1x1 {
                url
              }
            }
          }
          articleSubTitle
          issue {
            id
          }
        }
        ... on Craft_athenaArticles_athenaArticleCustom_Entry {
          coverImages {
            ... on Craft_coverImages_BlockType {
              ratio_1x1 {
                url
              }
            }
          }
          articleSubTitle
          issue {
            id
          }
        }
      }
      people: entries(section: "people") {
        id
        uri
        ... on Craft_people_person_Entry {
          nameFirstLine
          nameSecondLine
          portrait {
            id
            url
            width
            height
            focalPoint
            ... on Craft_people_Asset {
              altText
            }
          }
        }
      }
    }
  }
`
